<template>
  <words/>
</template>

<script>
import words from './components/words.vue'
// require('@/assets/style.css')
export default {
  name: 'App',
  components: {
    words
  }
}
</script>

<style>

@font-face {
  font-family: 'Harir';
  src: url('/public/harir.ttf') format('truetype');

}

/*}*/
body, #app{
  margin: 0 !important;
  margin-top: 0 !important;
  direction: rtl;
  font-family: 'Harir' !important;
}

/*Global*/
.d-flex{
  display: flex;
}
.align-items-center{
  align-items: center;
}
.justify-space-between{
  justify-content: space-between;
}
.text-right{
  text-align: right;
}
.mt-0{
  margin-top: 0;
}
.d-none{
  display: none !important;
}

#words{
  color: #000;
  background: #e9ddcf;
  min-height: 100vh;
  height: fit-content;
  position: relative;
}
#words:after{
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 160px;
  z-index: 0;
  background: url("/public/footer-bg.png") no-repeat ;
  background-size: cover;

}
.celebrate{
  position: absolute;
  z-index: -1;
  width: 30vw;
  right: 60%;
  top: 45%;
  height: 40vh;
  z-index: -1;
}

.container{
  padding: 40px 40px 10px 40px;
  position: relative;
  z-index: 1;
}
.row{
  display: flex;
}
.col-lg-6{
  width: 50%;
}

h5{
  font-size: 17px;
  font-weight: 600;
  margin: 0;
  padding-bottom: 12px;
}
h6{
  color: #6b4e45;
  font-size: 30px;
  font-weight: 800;
  margin: 0;
}
.mt-3{
  margin-top: 30px;
}
.mr-20{
  margin-right: 20px;
}
.mr-1{
  margin-right: 4px;
}
ul{
  list-style-type: decimal;
  padding-left: 0;
  padding-right: 14px;
  margin-top: 30px;
}
li{
  margin-bottom: 16px;
  font-size: 16px;

}
.question-logo{
  width: 53px;
  padding-top: 5px;
  margin-right: 10px;
}
.inputs{
  position: relative;
  transform: scale(.9);
  height: 390px;
  margin-top: -25px;
}
.inputs input{
  width: 13px;
  height: 13px;
  background: #fff;
  border: 1px solid #fff;
  margin: 3px;
  box-shadow: none;
  display: inline-block;
}
.inputs input:focus{
  border-color: #9a847d;
  outline: none;
}
.inputs .block{
  display: flex;
  align-items: center;
}
.inputs .num{
  font-weight: 500;
  font-size: 21px;
}
.inputs .inputs-1 .num{
  margin-left: 10px;
  margin-top: 2px;
}
.inputs .inputs-1{
  position: absolute;
  top: 91px;
  right: 30px;
  z-index: 10;
}
.inputs .inputs-2{
  position: absolute;
  top: 317px;
  right: 3px;
  z-index: 10;
}
.inputs .inputs-2 .num{
  margin-left: 10px;
}
.inputs .inputs-3{
  position: absolute;
  flex-direction: column;
  right: 242px;
  margin-top: 21px;
}
.inputs-3 input:nth-child(4){
  margin-top: 25px;
}
.inputs .inputs-4{
  position: absolute;
  flex-direction: column;
  right: 415px;
  top: 22px;
}
.inputs .inputs-5{
  position: absolute;
  flex-direction: column;
  right: 292px;
  top: 159px;
}
.inputs .inputs-6{
  position: absolute;
  top: 365px;
  right: 101px;
}
.inputs .inputs-6 .num{
  margin-left: 10px;
}
.inputs .inputs-7{
  position: absolute;
  flex-direction: column;
  right: 120px;
  top: 0;
}
.inputs-7 input:nth-child(5), .inputs-7 input:nth-child(9),
.inputs-7 input:last-child,
.inputs .inputs-4 input:nth-child(4),
.inputs .inputs-5 input:nth-child(10){
  margin-top: 25px;
}
.inputs .inputs-8{
  position: absolute;
  top: 206px;
  right: 52px;
}
.inputs .inputs-8 .num{
  margin-left: 10px;
}
.send-btns{
  margin-top: 50px;
  text-align: center;
}
.send-btns button{
  margin: 10px;
  min-width: 80px;
  height: 30px;
  border-radius: 4px;
  box-shadow: none;
  border: 0;
  border: 1px solid #6b4e45;
  cursor: pointer;
}
#submit{
  background: #6b4e45;
  color: #fff;
}
#submit:hover{
  background: #45302b;
}
#reset{
  background: #fff;
  border: 1px solid #ffff;
  color: #000;
}
#reset:hover{
  background: #6b4e45;
  color: #fff;
}
.container{
  max-width: 900px;
}
.try{
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 11;
  transform: translate(-50%, -50%);
  width: 300px;
  filter: invert(.9);
}
@media screen  and (max-width: 1800px){
  .container{
    width: 80vw;
    margin: auto;
  }
}
@media screen  and (max-width: 992px){
  .container{
    width: initial;
    margin: auto;
  }
  .col-lg-6{
    width: 100%;
  }
  .row{
    flex-direction: column;
  }
  .inputs{
    margin-right: -51px;
    height: 456px;
  }
}
@media screen  and (max-width: 567px){
  .title img{
    width: 120px;
  }
  .inputs {
    margin-right: -53px;
    transform: scale(.8);
    margin-top: -50px;
  }
  .send-btns {
    margin-top: 0px;
  }
  .container{
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media screen  and (max-width: 400px){
  .inputs{
    transform: scale(.68);
  }

}
@media screen  and (max-width: 400px){
  .inputs{
    margin-right: -86px;
    transform: scale(.65);
    margin-top: -77px;
    height: 424px;
  }
}
</style>
