<template>
  <div id="words">
    <img src="../assets/try.gif" alt="" class="try" v-if="showTry">
    <div class="container" >
      <div class="title d-flex align-items-center justify-space-between">
        <img src="../assets/founding-logo.png" alt="يوم التأسيس" title="يوم التأسيس">
        <a href="https://wakeb.tech" target="_blank"><img src="../assets/wakeb-logo.png" alt="واكب" title="واكب"></a>
      </div>
      <div id="confetti-elem"  class="celebrate"></div>
      <div id="balloons-elem"  class="celebrate"></div>
      <div id="emoji-elem"  class="celebrate"></div>
      <div class="row mt-3 text-right">
        <div class="col-lg-6">
          <div class="">
            <h5 class="mt-0">يوم التأسيس السعودي</h5>
            <h6 class="mt-0">أختبر معلوماتك</h6>
          </div>
          <ul>
            <li>من هو مؤسس الدولة السعودية الأولى</li>
            <li>تاريخ يوم التأسيس</li>
            <li>تقرر الاحتفاء بيوم التأسيس في عهد</li>
            <li>عاصمة الدوله السعودية الأولى</li>
            <li>من هو مؤسس الدولة السعوديه الثالثة</li>
            <li>ما هو شعار الهوية البصرية ليوم التأسيس</li>
            <li>من هو مؤسس الدولة السعودية التانية</li>
            <li>
              <div class="d-flex align-items-center">إلى ماذا يرمز هذا الشعار
                <img src="../assets/mark.png" alt="" class="question-logo">
              </div>
            </li>
          </ul>
        </div>
        <div class="col-lg-6">
          <div class="inputs">
            <div class="block inputs-1">
              <span class="num">1</span>
              <!-- 16 -->
              <input v-for="(char, index) in answers.answer1" v-model="answers.answer1[index]"
                     @input="isletter($event,'answer1', index)" :id="'answer1-'+index">
            </div>
            <div class="block inputs-2">
              <span class="num">2</span>
              <!-- 8 -->
              <input v-for="(char, index) in answers.answer2" v-model="answers.answer2[index]"
                     @input="isletter($event,'answer2', index)"  :id="'answer2-'+index">
            </div>
            <div class="block inputs-3">
              <span class="num">3</span>
              <!-- 10 -->
              <input v-for="(char, index) in answers.answer3" v-model="answers.answer3[index]"
                     @input="isletter($event,'answer3', index)"  :id="'answer3-'+index">
            </div>
            <div class="block inputs-4">
              <span class="num">4</span>
              <!-- 7 -->
              <input v-for="(char, index) in answers.answer4" v-model="answers.answer4[index]"
                     @input="isletter($event,'answer4', index)"  :id="'answer4-'+index">

            </div>
            <div class="block inputs-5">
              <span class="num">5</span>
              <!-- 14 -->
              <input v-for="(char, index) in answers.answer5" v-model="answers.answer5[index]"
                     @input="isletter($event,'answer5', index)"  :id="'answer5-'+index">
            </div>
            <div class="block inputs-6">
              <span class="num">6</span>
              <!-- 8 -->
              <input v-for="(char, index) in answers.answer6" v-model="answers.answer6[index]"
                     @input="isletter($event,'answer6', index)"  :id="'answer6-'+index">
            </div>
            <div class="block inputs-7">
              <span class="num">7</span>
              <!-- 16 -->
              <input v-for="(char, index) in answers.answer7" v-model="answers.answer7[index]"
                     @input="isletter($event,'answer7', index)"  :id="'answer7-'+index">
            </div>
            <div class="block inputs-8">
              <span class="num">8</span>
              <!-- 6 -->
              <input v-for="(char, index) in answers.answer8" v-model="answers.answer8[index]" :key="index"
                     @input="isletter($event,'answer8', index)"  :id="'answer8-'+index">

            </div>
          </div>
        </div>
      </div>
      <div class="send-btns">
        <button id="submit" @click="checkAnswer">ارسال</button>
        <button id="reset" @click="reset">اعادة</button>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { useReward } from 'vue-rewards'
const { reward: confettiReward, isAnimating: isConfettiAnimating } = useReward('confetti-elem', 'confetti');
const { reward: balloonsReward, isAnimating: isBalloonsAnimating } = useReward('balloons-elem', 'balloons');
const { reward: emojiReward, isAnimating: isEmojiAnimating } = useReward('emoji-elem', 'emoji');
const { reward: fullPageReward, isAnimating: isFullPageAnimating } = useReward('full-page', 'confetti', {startVelocity:10, spread:180, elementCount:100 });

export default {
  name: 'words',
  props: {
    msg: String
  },
  data() {
    return {
//       1 الإمام محمد بن سعود
//       2 . ٢٢ فبراير
// ٣. ٣ الملك سلمان
// ٤. ⁠٤ الدرعية
// ٥. ⁠٥ الملك عبدالعزيز
// ٦. ⁠ ٦ يوم بدينا
// ٧. ⁠٧ الإمام فيصل بن تركي
// ٨. ⁠٨ الصقر
      correctAnswers: {
        answer1: ['ا', 'ل', 'ا', 'م', 'ا', 'م', 'م', 'ح', 'م', 'د', 'ب', 'ن', 'س', 'ع', 'و', 'د'],
        answer2: ['2', '2', 'ف', 'ب', 'ر', 'ا', 'ي', 'ر'],
        // answer3: ['ا','ل','م','ل','ك','س','ل','م','ا','ن'],
        answer3: ['ا', 'ل', 'ل', 'ك', 'س', 'ل', 'م', 'ا', 'ن'],
        answer4: ['ا', 'ل', 'ر', 'ع', 'ي', 'ة'],
        // answer5: ['ا','ل','م','ل','ك','ع','ب','د','ا','ل','ع','ز','ي','ز'],
        answer5: ['ا', 'ل', 'م', 'ل', 'ك', 'ع', 'ب', 'د', 'ل', 'ع', 'ز', 'ي', 'ز'],
        answer6: ['ي', 'و', 'م', 'ب', 'د', 'ي', 'ن', 'ا'],
        // answer7: ['ا','ل','إ','م','ا','م','ف','ي','ص','ل','ب','ن','ت','ر','ك','ي'],
        answer7: ['ا', 'ل', 'ا', 'ا', 'م', 'ف', 'ي', 'ل', 'ب', 'ن', 'ت', 'ك',],
        answer8: ['ا', 'ل', 'ص', 'ق', 'ر']
      },
      answers: {
        answer1: ['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
        answer2: ['', '', '', '', '', '', '', ''],
        // answer3: ['','','','','','','','','',''],
        answer3: ['', '', '', '', '', '', '', '', ''],
        answer4: ['', '', '', '', '', ''],
        answer5: ['', '', '', '', '', '', '', '', '', '', '', '', ''],
        // answer5: ['','','','','','','','','','','','','',''],
        answer6: ['', '', '', '', '', '', '', ''],
        // answer7: ['','','','','','','','','','','','','','','',''],
        answer7: ['', '', '', '', '', '', '', '', '', '', '', ''],
        answer8: ['', '', '', '', '']
      },
      focusNext(elem) {
        const currentIndex = Array.from(elem.form.elements).indexOf(elem);
        elem.form.elements.item(
            currentIndex < elem.form.elements.length - 1 ?
                currentIndex + 1 :
                0
        ).focus();
      },
      prevID: '',
      showTry: false
    }

  },
  methods: {
    checkAnswer() {

      const check1 = _.isEqual(this.answers.answer1, this.correctAnswers.answer1);
      const check2 = _.isEqual(this.answers.answer2, this.correctAnswers.answer2);
      const check3 = _.isEqual(this.answers.answer3, this.correctAnswers.answer3);
      const check4 = _.isEqual(this.answers.answer4, this.correctAnswers.answer4);
      const check5 = _.isEqual(this.answers.answer5, this.correctAnswers.answer5);
      const check6 = _.isEqual(this.answers.answer6, this.correctAnswers.answer6);
      const check7 = _.isEqual(this.answers.answer7, this.correctAnswers.answer7);
      const check8 = _.isEqual(this.answers.answer8, this.correctAnswers.answer8);
      if (check1 && check2 && check3 && check4 && check5 && check6 && check7 && check8) {
        confettiReward();balloonsReward();emojiReward();
        console.log('Bravo')
      } else {
        this.showTry = true;
        const vm = this;
        setTimeout(function (){
          vm.showTry = false;
        }, 1500);
        console.log('Try Again')
      }
    },
    reset() {
      this.answers.answer1 = this.answers.answer1.map(c => {
        return ''
      })
      this.answers.answer2 = this.answers.answer2.map(c => {
        return ''
      })
      this.answers.answer3 = this.answers.answer3.map(c => {
        return ''
      })
      this.answers.answer4 = this.answers.answer4.map(c => {
        return ''
      })
      this.answers.answer5 = this.answers.answer5.map(c => {
        return ''
      })
      this.answers.answer6 = this.answers.answer6.map(c => {
        return ''
      })
      this.answers.answer7 = this.answers.answer7.map(c => {
        return ''
      })
      this.answers.answer8 = this.answers.answer8.map(c => {
        return ''
      })


    },
    isletter(event,arr, index) {
      // console.log(event)
      const inputID = event.target.id;
      this.prevID = event.target.previousSibling?.id;
      event.target.value = event.target.value
      this.answers[arr][index] = '';
      this.answers[arr][index] = event.target.value;
      console.log(this.answers , index)
      if (event.target.value.length) {
        event.preventDefault();
        if(inputID == 'answer7-2'){
          document.getElementById('answer1-3').focus()
          document.getElementById('answer1-3').select()
        }
        else if(inputID == 'answer3-1'){
          document.getElementById('answer1-8').focus()
          document.getElementById('answer1-8').select()
        }
        else if(inputID == 'answer4-1'){
          document.getElementById('answer1-15').focus()
          document.getElementById('answer1-15').select()
        }
        else if(inputID == 'answer7-6'){
          document.getElementById('answer8-2').focus()
          document.getElementById('answer8-2').select()
        }
        else if(inputID == 'answer7-10'){
          document.getElementById('answer2-4').focus()
          document.getElementById('answer2-4').select()
        }
        else if(inputID == 'answer7-11'){
          document.getElementById('answer6-0').focus()
          document.getElementById('answer6-0').select()
        }
        else if(inputID == 'answer5-7'){
          document.getElementById('answer6-7').focus()
          document.getElementById('answer6-7').select()
        }
        else {
          console.log(this.answers[arr].length, index +1)
          if(this.answers[arr].length != index+1){
            event.target.nextSibling?.focus()
            event.target.nextSibling?.select()
          }

        }
      }
    },
  }
}
</script>


